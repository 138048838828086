import React from "react";
import './ReviewCard.css';
import Star from '../../../Assets/Images/star.png';

const ReviewCard = ({ name, stars, review }) => {
  return (
    <div className="ReviewDiv">
      <h4>{name}</h4>
      <div>
        {Array.from({ length: stars }, (_, i) => (
          <img
            key={i}
            style={{ width: "25px", marginRight: "10px" }}
            src={Star}
            alt="star"
          />
        ))}
      </div>
      <p>{review}</p>
    </div>
  );
};

export default ReviewCard;
