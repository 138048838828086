import React, { useRef, useState } from "react";
import "./CollageCarousel.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { GoArrowLeft } from "react-icons/go";
const CollageCarousel = ({ images }) => {
  const containerRef = useRef(null);
  const [modalImage, setModalImage] = useState(null); // Modal state

  const scrollAmount = 600; // Amount to scroll with each arrow click

  const handleScroll = (direction) => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const newScrollPosition =
      direction === "left"
        ? container.scrollLeft - scrollAmount
        : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollPosition,
      behavior: "smooth",
    });
  };

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div className="collage-carousel">
      <button className="arrow left" onClick={() => handleScroll("left")}>
        <SlArrowLeft />
      </button>
      <div className="collage-container" ref={containerRef}>
        {images.map((image, index) => (
          <div
            key={index}
            className="collage-item"
            style={{
              gridRow: `span ${image.gridRow || 1}`,
              gridColumn: `span ${image.gridColumn || 1}`,
            }}
          >
            <img
              src={image.src}
              alt={image.alt || `Image ${index + 1}`}
              onClick={() => openModal(image)}
            />
          </div>
        ))}
      </div>
      <button className="arrow right" onClick={() => handleScroll("right")}>
        <SlArrowRight />
      </button>

      {/* Modal for Mobile */}
      {modalImage && (
        <div className="modal">
          <button className="back-button" onClick={closeModal}>
            <GoArrowLeft />
          </button>
          <img src={modalImage.src} alt={modalImage.alt || "Full Image"} />
        </div>
      )}
    </div>
  );
};

export default CollageCarousel;
