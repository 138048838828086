import React, { useState } from "react";

import './RecentProp.css';
import PropCard from '../../Shared/PropCard/PropCard';
import Pagination from '../../Shared/Pagination/Pagination';
import Img1 from '../../../Assets/Images/PropCoverImg/1.png';
import Img2 from '../../../Assets/Images/PropCoverImg/2.png';
import Img3 from '../../../Assets/Images/PropCoverImg/3.png';
import Img4 from '../../../Assets/Images/PropCoverImg/4.png';
import Img5 from '../../../Assets/Images/PropCoverImg/5.png';
import Img6 from '../../../Assets/Images/PropCoverImg/6.png';
import Img7 from '../../../Assets/Images/PropCoverImg/7.png';

const RecentProp = () => {
  const properties = [
    {
      imgSrc: Img1,
      location: "Mountlake Terrace, WA",
      tag: "BUYER",
      address: "22505 66th Ave W",
      details: "4 Bed 3 Bath",
      area: "3,650",
      listedPrice: "$690,000",
      soldPrice: "$662,000",
      paddingTop: 40,
    },
    {
      imgSrc: Img2,
      location: "Seattle, WA",
      tag: "BUYER",
      address: "750 N 143rd St",
      details: "1 Bed 1 Bath",
      area: "694",
      listedPrice: "$265,000",
      soldPrice: "$260,000",
      paddingTop: 20,
    },
    {
      imgSrc: Img3,
      location: "Seattle, WA",
      tag: "BUYER",
      address: "1051 8th Ave NE",
      details: "2 Bed 2 Bath",
      listedPrice: "$300,000",
      soldPrice: "$285,000",
      paddingTop: 20,
      area: "720",
    },
    {
      imgSrc: Img4,
      location: "Bellevue, WA",
      tag: "SELLER",
      address: "15617 NE 18th St",
      details: "2 Bed 2 Bath",
      listedPrice: "$278,000",
      soldPrice: "$278,000",
      paddingTop: 20,
      area: "1,817",
    },
    {
      imgSrc: Img5,
      location: "Mountlake Terrace, WA",
      tag: "BUYER",
      address: "22505 66th Ave W",
      details: "4 Bed 3 Bath",
      listedPrice: "$690,000",
      soldPrice: "$662,000",
      paddingTop: 40,
      area: "2,200",
    },
    {
      imgSrc: Img6,
      location: "Seattle, WA",
      tag: "BUYER",
      address: "7712 Aurora Ave N",
      details: "Commercial",
      listedPrice: "$520,000",
      soldPrice: "$500,000",
      paddingTop: 20,
      area: "1,200",
    },
    {
      imgSrc: Img7,
      location: "SeaTac, WA",
      tag: "BUYER",
      address: "19740 Military Rd S",
      details: "9 Bed 6 Bath",
      listedPrice: "$595,000",
      soldPrice: "$595,000",
      paddingTop: 20,
      area: "6,200",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 4;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = properties.slice(indexOfFirstCard, indexOfLastCard);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(properties.length / cardsPerPage);

  return (
    <div className="RecentPropCont">
      <h2>🏘 Recent Properties 🏘</h2>
      <p className="PropPara">
        As a local and independent Agent, I use my sales & marketing strategies and innovative negotiation skills to ensure<br className="BrDesk"/>
        local property buyers and sellers receive the highest level of services for all their real estate needs.
      </p>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "30px", justifyContent: 'center', marginTop: "40px" }}>
        {currentCards.map((property, index) => (
          <PropCard
            key={index}
            imgSrc={property.imgSrc}
            location={property.location}
            tag={property.tag}
            address={property.address}
            details={property.details}
            listedPrice={property.listedPrice}
            soldPrice={property.soldPrice}
            paddingTop={property.paddingTop}
            area={property.area}
          />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        marginTop='100'
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default RecentProp;
