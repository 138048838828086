import React from "react";
import CollageCarousel from "../../Shared/CollageCarousel/CollageCarousel";
import Img1 from "../../../Assets/Images/GalleryImg/Img1.jpg";
import Img2 from "../../../Assets/Images/GalleryImg/Img2.jpg";
import Img3 from "../../../Assets/Images/GalleryImg/Img3.jpg";
import Img4 from "../../../Assets/Images/GalleryImg/Img4.jpg";
import Img5 from "../../../Assets/Images/GalleryImg/Img5.jpg";
import Img6 from "../../../Assets/Images/GalleryImg/Img6.jpg";
import Img7 from "../../../Assets/Images/GalleryImg/Img7.jpg";
import Img8 from "../../../Assets/Images/GalleryImg/Img8.jpg";
import Img9 from "../../../Assets/Images/GalleryImg/Img9.jpg";
import HeaderIcon from "../../../Assets/Images/HeaderIcon.png";
import './Gallery.css'
const Gallery = () => {
  const images = [
    { src: Img1, alt: "Image 1", gridRow: 4, gridColumn: 3 },
    { src: Img2, alt: "Image 2", gridRow: 2, gridColumn: 2 },
    { src: Img4, alt: "Image 4", gridRow: 2, gridColumn: 4 },
    { src: Img3, alt: "Image 3", gridRow: 2, gridColumn: 2 },
    { src: Img5, alt: "Image 5", gridRow: 1, gridColumn: 3 },
    { src: Img6, alt: "Image 6", gridRow: 3, gridColumn: 3 },
    { src: Img7, alt: "Image 7", gridRow: 3, gridColumn: 2 },
    { src: Img8, alt: "Image 8", gridRow: 1, gridColumn: 2 },
    { src: Img9, alt: "Image 9", gridRow: 4, gridColumn: 3 },
  ];

  return (
    <div>
      <CollageCarousel images={images} />
      <div className="FooterCont">
        <div style={{ width: "130px" }}></div>
        <img className="FooterIcon" src={HeaderIcon} />
        <div className="ContactFooter">
          <span>
            📧 <a href='mailto:davydovhomes@gmail.com'>davydovhomes@gmail.com</a>
          </span>
          <span>
            📍Seattle & Miami
          </span>
          <span>
            📱
            <a href="tel:+12063702783">
              (206) 370-2783
            </a>
          </span>
          <span>
            👤
            <a href="https://www.linkedin.com/in/jacob-davydov/">
              LinkedIn
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
