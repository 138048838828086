import React from "react";
import IntroHeadBg from '../../../Assets/Videos/IntroHeadBg.mp4';
import './MainPage.css'
import PersonImg from '../../../Assets/Images/PersonImg.png'

const ContactDetailDiv = () => {
  return (
    <div className="ContactDiv">
      <span>
        📍Seattle & Miami
      </span>
      <span>
        📱
        <a href="tel:+12063702783">
          (206) 370-2783
        </a>
      </span>
      <span>
        👤
        <a href="https://www.linkedin.com/in/jacob-davydov/">
          LinkedIn
        </a>
      </span>
    </div >
  )
}
const MainPage = () => {
  return (
    <>
      <div className="MainPageCon">
        <video playsInline preload="auto" muted loop autoPlay src={IntroHeadBg} className="BgVideo" />
        <div className="DescDiv">
          <div className="PersonBgImg">
            <div className="ContactCont">
              <ContactDetailDiv />
            </div>
            <img className="PersonImg" src={PersonImg} />
          </div>
          <div className="AboutPersonDiv">
            <div className="AbtPersonHeading">
              <h3>Real Estate Broker</h3>
              <h2>Jacob<br /> Davydov</h2>
            </div>
            <div className="AboutCont">
              <div className="AboutPara">
                <div className="MobContactDetailDiv">
                  <ContactDetailDiv />
                </div>
                <p>
                  When it comes to working for you, Jacob pledges his<br className="BrDesk" /> upmost attention and effort!<br /><br />
                  Buying or selling a house is one of the most important<br className="BrDesk" /> transactions in your life, so it can be stressful and tricky.<br className="BrDesk" /> With his knowledge of the housing market and surrounding<br className="BrDesk" /> communities as a resident of the Greater Seattle Area,<br className="BrDesk" /> Jacob can help you land your perfect home and ensure a<br className="BrDesk" /> smooth sale. His goal is to provide you with expert, caring,<br className="BrDesk" /> and candid advice through every step of the way. That is<br className="BrDesk" /> his commitment to you.<br /><br />
                  For two years, he trained under a vigorous program at<br className="BrDesk" /> Windermere, the top real estate company in the Pacific<br className="BrDesk" /> Northwest. His enthusiasm, work ethic, resource<br className="BrDesk" /> management skills and extensive knowledge of the Seattle<br className="BrDesk" /> and Miami Metropolitan Area make him uniquely qualified<br className="BrDesk" /> to work with you in purchasing or selling a home in<br className="BrDesk" /> Washington or Florida!
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
