import React from "react";
import Header from "../Component/Shared/Header/Header";
import MainPage from "../Component/Others/MainPage/MainPage";
import BuySell from "../Component/Others/BuySell/BuySell";
import RecentProp from "../Component/Others/RecentProp/RecentProp";
import Reviews from "../Component/Others/Reviews/Reviews";
//import ContactMe from "../Component/Others/ContactMe/ContactMe";
import Gallery from "../Component/Others/Gallery/Gallery";

const LandingPage = () => {
  return (
    <>
      <Header />
      <MainPage />
      <BuySell />
      <RecentProp />
      <Reviews />
      {/* <ContactMe />*/}
      <Gallery />
    </>
  )
}
export default LandingPage;