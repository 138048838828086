import React, { useState } from "react";
import './Reviews.css';
import ReviewCard from '../../Shared/ReviewCard/ReviewCard';
import Pagination from '../../Shared/Pagination/Pagination';

const Reviews = () => {
  const reviews = [
    {
      name: "Daryl C.",
      stars: 5,
      review:
        "Jacob has been an attentive and dedicated realtor as I went through the process as a first-time homeowner. I was in the more unique situation of moving out of state after my home sale and Jacob not only helped me make a game plan but executed it without a hitch. He even stepped in to coordinate additional home repairs when I needed to focus on my move. Jacob’s earned my highest recommendation with his knowledge base, professionalism, and being willing to go the extra mile. Thank you again, Jacob!",
    },
    {
      name: "Sam D.",
      stars: 5,
      review:
        "I got referred to Jacob through a friend and he is a great realtor to work with. It is clear to me that he is very skilled, and yet he is humble and easy to get along with! Jacob made the process of understanding what type of investment I was looking for a whole lot easier. He challenged my perspective and made me think outside the box. He went above and beyond answering any questions I had along with providing additional assistance. Always a pleasure speaking and working with him!",
    },
    {
      name: "Mike C.",
      stars: 5,
      review:
        "Jacob came recommended by a close friend, and he didn't disappoint. I had no idea what I was looking for, and he was patient with me while helping me work out precisely what I wanted. What I appreciated most was that he was quick to point out things I hadn't noticed, to encourage me to consider other perspectives. He didn't just want to find me a home - he wanted to find the right one for me. This was clearly important to him, and it made the process less stressful than it could have been. I had such a good experience buying with Jacob and I’ll gladly work with him again. Once again, he didn't disappoint!",
    },
    {
      name: "Juan J.",
      stars: 5,
      review:
        "It was time for me to start looking into purchasing a property and I got referred to Jacob through a friend. His was very responsive at all times via text, call or email. Being a first-time home buyer, I wasn’t too familiar with the process. Jacob was able to find time to sit down with me in person and break down the process. He provided me with all the knowledge, tools and resources he had available to make this the perfect purchase for me. He clearly knows the market and strategy, I highly recommend!",
    },
    {
      name: "David P.",
      stars: 5,
      review:
        "I couldn’t have found a better person to help me understand how the real estate process works! He has a lot of knowledge on the different markets in the Greater Seattle area and what to look for. Extremely pleased!",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 2;
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  return (
    <div className="ReviewsCont">
      <h2>Customer Reviews</h2>
      <div className="ReviewMainDiv">
        {currentReviews.map((review, index) => (
          <ReviewCard
            key={index}
            name={review.name}
            stars={review.stars}
            review={review.review}
          />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        marginTop='10'
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Reviews;
