import React from "react";
import './PropCard.css';

const PropCard = ({ imgSrc, location, tag, address, details, listedPrice, soldPrice, paddingTop, area }) => {
  return (
    <div className="PropCard">
      <img src={imgSrc} alt={location} />
      <div
        className="PropInfoDiv"
        style={{ paddingTop: `${paddingTop}px` }} // Dynamically set padding
      >
        <div className="CardHeading">
          <h4>{location}</h4>
        </div>
        <span className="CardTags">{tag}</span>
        <div className="PropInfo">
          <div>
            <strong>{address}<br />{details}</strong>
            <p>Listed for</p>
            <h3>{listedPrice}</h3>
          </div>
          <div>
            <strong><br />{area} sq ft</strong>
            <p>Sold for</p>
            <h3>{soldPrice}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropCard;
