import React from "react";
import HeaderIcon from '../../../Assets/Images/HeaderIcon.png'
import "./Header.css"
const Header = () => {
  return (
    <>
      <div className="HeaderDiv">
        <img className="BrandIcon" src={HeaderIcon} />
        <h2 className="HeaderHeading">
          DAVYDOV HOMES
        </h2>
      </div>
      <hr className="HrLine"/>
    </>
  )
}
export default Header;